html {
  background-color: #000000;
}

.root{
  background-color: #fce6c2;
}

.App {
  text-align: center;
  padding-top: 10%;
  background: #f0bd6c url("https://i.imgur.com/ahMqbDe.png") no-repeat;
  background-size: 90px 145px;
  background-position-x: 50%;
  background-position-y: calc(100% - 170px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Tea {
  width: 214px;
  height: 150px;
  margin: 0 auto;
  margin-top: 8%;
  background-image: linear-gradient(to right, #000000 5px, #000000 20px, transparent 1px, transparent 25px, #000000 5px, #000000 182px, transparent 20px, transparent 186px, #000000 45px, #000000 201px, transparent 25px, transparent 206px, #000000 40px, #000000 205px);
  background-position: left bottom;
  background-size: 295px 6px;
  background-repeat: repeat-x; 
}

.Tea:before, .Tea:after{
  
  position:absolute;
  display: block;
}

.Tea:before {
  content: "";
  box-sizing: border-box;
  margin-left: 120px;
  margin-top: 40px;
  width: 95px;
  height: 65px;
  border-radius: 20px 2px 70px 70px;
  border: 8px solid #eeeeee;
  border-top-color: #eeeeee;
  border-bottom-color: #eeeeee;
  box-shadow: inset 0 0 0 5px #000000, 0 0 0 15px white, 0 0 0 20px #000000;
}

.Tea:after{
  box-sizing: content-box;
  content: 'x';
  font-size: 19px;
  margin-left: 32px;
  font-family: comic sans ms;
  line-height: 1;
  font-weight: bold;
  color: rgb(165, 255, 200);
  /* text-shadow: rgb(240, 189, 108) -97px 5px 2px, rgb(240, 189, 108) -101px 5px 2px, rgb(240, 189, 108) -95px 23px 2px, rgb(240, 189, 108) -111px 23px 2px, rgb(240, 189, 108) -90px 50px 2px, rgb(240, 189, 108) -106px 50px 2px, rgb(240, 189, 108) -108px 67px 2px, rgb(240, 189, 108) -92px 67px 2px, rgb(240, 189, 108) 88px -64px 2px, rgb(240, 189, 108) 87px -48px 2px, rgb(240, 189, 108) 103px -62px 2px, rgb(240, 189, 108) 103px -46px 2px, rgb(240, 189, 108) 118px -61px 2px, rgb(240, 189, 108) 119px -79px 2px, rgb(240, 189, 108) 103px -79px 2px, rgb(249, 211, 150) -112px 14px 1px, rgb(249, 211, 150) -137px 40px 1px, rgb(249, 211, 150) -109px 58px 1px, rgb(249, 211, 150) 95px -55px 1px, rgb(249, 211, 150) 110px -70px 1px, rgb(249, 211, 150) 129px 84px 1px; */
  width:100px;
  height:100px;
  background:#f4745f;
  border: 5px solid #000000;
  padding:20px;
  border-radius: 5px 5px 30px 30px;
  background-image: linear-gradient(to right, rgb(165, 255, 200) 10%, transparent 10%), linear-gradient(to left, #eeeeee 30%, white 5px, white 35px, white 90px, transparent 10%), linear-gradient(to bottom, transparent 8px, transparent 15px, #000000 10px, #000000 20px, transparent 20px, transparent 52px, #000000 10px, #000000 57px, white 5%), linear-gradient(to left, #eeeeee 35px, transparent 50px, transparent 106px, #000000 5px, #000000 111px, transparent 65px, transparent 104px, transparent 95px, transparent 121px, transparent 110px, transparent 5px, transparent 135px, transparent 135px), linear-gradient(to bottom, white 8px, white 15px, #000000 10px, #000000 20px, transparent 20px, transparent 52px, #000000 10px, #000000 57px, transparent 5%), linear-gradient(to top, white 60%, transparent 60%), linear-gradient(to left, #eeeeee 35px, white 50px, white 90px, #000000 5px, #000000 95px, #000000 65px, #000000 106px, #f7aa40 95px, #f7aa40 121px, #f7aa40 110px, #000000 5px, #000000 135px, transparent 135px);
}

.linkIcon:hover{
  background-color:#383838;
}

a:hover{
  color:#f7aa40;
  /* border-bottom: #f0bd6c;
  border-width: 2px; */
}

.reset-this * {
  animation : none;
  animation-delay : 0;
  animation-direction : normal;
  animation-duration : 0;
  animation-fill-mode : none;
  animation-iteration-count : 1;
  animation-name : none;
  animation-play-state : running;
  animation-timing-function : ease;
  backface-visibility : visible;
  background : 0;
  background-attachment : scroll;
  background-clip : border-box;
  background-color : transparent;
  background-image : none;
  background-origin : padding-box;
  background-position : 0 0;
  background-position-x : 0;
  background-position-y : 0;
  background-repeat : repeat;
  background-size : auto auto;
  border : 0;
  border-style : none;
  border-width : medium;
  border-color : inherit;
  border-bottom : 0;
  border-bottom-color : inherit;
  border-bottom-left-radius : 0;
  border-bottom-right-radius : 0;
  border-bottom-style : none;
  border-bottom-width : medium;
  border-collapse : separate;
  border-image : none;
  border-left : 0;
  border-left-color : inherit;
  border-left-style : none;
  border-left-width : medium;
  border-radius : 0;
  border-right : 0;
  border-right-color : inherit;
  border-right-style : none;
  border-right-width : medium;
  border-spacing : 0;
  border-top : 0;
  border-top-color : inherit;
  border-top-left-radius : 0;
  border-top-right-radius : 0;
  border-top-style : none;
  border-top-width : medium;
  bottom : auto;
  box-shadow : none;
  box-sizing : content-box;
  caption-side : top;
  clear : none;
  clip : auto;
  color : inherit;
  columns : auto;
  column-count : auto;
  column-fill : balance;
  column-gap : normal;
  column-rule : medium none currentColor;
  column-rule-color : currentColor;
  column-rule-style : none;
  column-rule-width : none;
  column-span : 1;
  column-width : auto;
  content : normal;
  counter-increment : none;
  counter-reset : none;
  cursor : auto;
  direction : ltr;
  display : inline;
  empty-cells : show;
  float : none;
  font : normal;
  font-family : inherit;
  font-size : medium;
  font-style : normal;
  font-variant : normal;
  font-weight : normal;
  height : auto;
  hyphens : none;
  left : auto;
  letter-spacing : normal;
  line-height : normal;
  list-style : none;
  list-style-image : none;
  list-style-position : outside;
  list-style-type : disc;
  margin : 0;
  margin-bottom : 0;
  margin-left : 0;
  margin-right : 0;
  margin-top : 0;
  max-height : none;
  max-width : none;
  min-height : 0;
  min-width : 0;
  opacity : 1;
  orphans : 0;
  outline : 0;
  outline-color : invert;
  outline-style : none;
  outline-width : medium;
  overflow : visible;
  overflow-x : visible;
  overflow-y : visible;
  padding : 0;
  padding-bottom : 0;
  padding-left : 0;
  padding-right : 0;
  padding-top : 0;
  page-break-after : auto;
  page-break-before : auto;
  page-break-inside : auto;
  perspective : none;
  perspective-origin : 50% 50%;
  position : static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes : '\201C' '\201D' '\2018' '\2019';
  right : auto;
  tab-size : 8;
  table-layout : auto;
  text-align : inherit;
  text-align-last : auto;
  text-decoration : none;
  text-decoration-color : inherit;
  text-decoration-line : none;
  text-decoration-style : solid;
  text-indent : 0;
  text-shadow : none;
  text-transform : none;
  top : auto;
  transform : none;
  transform-style : flat;
  transition : none;
  transition-delay : 0s;
  transition-duration : 0s;
  transition-property : none;
  transition-timing-function : ease;
  unicode-bidi : normal;
  vertical-align : baseline;
  visibility : visible;
  white-space : normal;
  widows : 0;
  width : auto;
  word-spacing : normal;
  z-index : auto;
  /* basic modern patch */
  all: initial;
  all: unset;
}